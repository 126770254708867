export default class LanguageKey {
	constructor(key: string) {
    let matches: RegExpMatchArray | null = key.match(
      new RegExp("^([a-zA-Z]{2})(?:\-([a-zA-Z]{2,3}))?$")
    );

    this.key = key;
		this.language = matches && matches[1] ? matches[1] : "";
		this.locale = matches && matches[2] ? matches[2] : "";
	}

  /**
   * Full language key
   */
	key: string;

  /**
   * Language part of the key
   */
	language: string;

  /**
   * Locale part of the key
   */
	locale: string;
}
