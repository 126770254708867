import * as Cookies from "js-cookie";
import ILanguageCookie from "@/ts/types/language/interfaces/ipersistentlanguage";
import ILanguageSelector from "@/ts/modules/language/interface/ilanguageselector";
import SelectorOptions from "@/ts/modules/language/selectoroptions";
import LanguageKey from "@/ts/types/language/languagekey";
import ILanguage from "@/ts/types/language/interfaces/ilanguage";

export default class CookieLanguageSelector implements ILanguageSelector {
	browser_language: LanguageKey;
	cookie_options: object;
	is_subscribed: boolean;
	selector_options: SelectorOptions;

	constructor(
		options: SelectorOptions,
		browser_language_key: LanguageKey,
		cookie_options: object | null
	) {
		this.browser_language = browser_language_key;
		this.cookie_options = cookie_options || {};
		this.is_subscribed = false;
		this.selector_options = options;
	}

	/**
	 * @inheritDoc
	 */
	initialize(): void {
		let initial_language: LanguageKey = this.browser_language;

		// If redirects are enabled on load, enable redirect subscriptions
		if (this.selector_options.use_redirect_on_load()) {
			this.enable_redirect_subscription();
			initial_language =
				this.read_stored_language() ?? this.browser_language;
		}

		this.select_language(initial_language);

		// If redirects are enabled for links only, not on load,
		// enable redirects after the initial language setup
		!this.is_subscribed && this.selector_options.use_redirect_on_select()
			? this.enable_redirect_subscription()
			: null;
	}

	/**
	 * Manage redirect actions on language change
	 */
	enable_redirect_subscription(): void {
		this.selector_options.current_language.subscribe((key: LanguageKey) => {
			this.process_redirect(key);
		});
		this.is_subscribed = true;
	}

	/**
	 * @inheritDoc
	 */
	protected read_redirect_url(key: LanguageKey): string | null {
		let language: ILanguage | null = this.selector_options.read_language_by_key(
			key
		);
		return language &&
			this.selector_options.current_location.host.toLowerCase() !==
				language.site_domain.toLowerCase()
			? this.selector_options.current_location.protocol +
					"//" +
					language.site_domain +
					this.selector_options.current_location.pathname
			: null;
	}

	/**
	 * Read the stored language key
	 * @protected
	 */
	protected read_stored_language(): LanguageKey | null {
		let cookie: ILanguageCookie | null = Cookies.getJSON(
			this.selector_options.persistent_name
		);
		return cookie && this.selector_options.redirect_on_load
			? new LanguageKey(cookie.language_key)
			: null;
	}

	/**
	 * @inheritDoc
	 */
	process_redirect(key: LanguageKey): void {
		let stored_language: LanguageKey | null = this.read_stored_language();
		let url: string | null = this.read_redirect_url(key);

		// Create/Update the cookie if not set
		if (
			this.selector_options.use_redirect_on_load() &&
			(!stored_language || stored_language.key !== key.key)
		) {
			this.update_stored_language(key);
		}

		url ? window.location.replace(url) : null;
	}

	/**
	 * @inheritDoc
	 */
	select_language(key: LanguageKey): void {
		this.selector_options.update_language(key);
	}

	/**
	 * Create/Update the stored language key
	 *
	 * @param key
	 * @protected
	 */
	protected update_stored_language(key: LanguageKey): void {
		Cookies.set(
			this.selector_options.persistent_name,
			<ILanguageCookie>{
				language_key: key.key
			},
			this.cookie_options
		);
	}
}
