import CookieLanguageSelector from "@/ts/modules/language/cookielanguageselector";
import ILanguage from "@/ts/types/language/interfaces/ilanguage";
import ILanguages from "@/ts/types/language/interfaces/ilanguages";
import ILanguageSelector from "@/ts/modules/language/interface/ilanguageselector";
import LanguageKey from "@/ts/types/language/languagekey";
import LanguageSelectorButton from "@/ts/modules/language/languageselectorbutton";
import SelectorOptions from "@/ts/modules/language/selectoroptions";
import LanguageButtonOptions from "@/ts/modules/language/languagebuttonoptions";

const english: ILanguage = <ILanguage>{
	display_name: "USA - English",
	site_domain: "www.chemtreat.com"
};
const available_languages: ILanguages = {
	default_language: english,
	en: <ILanguages>{
		default_language: english
	},
	es: <ILanguage>{
		display_name: "Latin America - Español",
		site_domain: "es.chemtreat.com"
	},
  fr: <ILanguage>{
    display_name: "Canada - French",
    site_domain: "fr.chemtreat.com"
  },
	pt: <ILanguage>{
		display_name: "Brazil - Português",
		site_domain: "pt.chemtreat.com"
	}
};

let environment_languages: ILanguages =
	window.chemtreat_language_configuration || available_languages;

let selector_options: SelectorOptions = new SelectorOptions(
	environment_languages
);
selector_options.persistent_name = "cht_selected_languages";
selector_options.redirect_enabled =
	false !== window.chemtreat_language_redirect_enabled;
selector_options.redirect_on_load =
  false !== window.chemtreat_language_redirect_on_load;
selector_options.redirect_on_select =
  false !== window.chemtreat_language_redirect_on_select;

let selector_collection: Array<LanguageSelectorButton> = [];

let selector_elements: HTMLCollection = document.getElementsByClassName(
	"language-selector"
);

let language_selector: ILanguageSelector = new CookieLanguageSelector(
	selector_options,
	new LanguageKey(window.navigator.language),
	window.chemtreat_cookie_configuration || {
		secure: true,
		sameSite: "strict",
		path: "/",
		domain: ".chemtreat.com",
		expires: 365
	}
);

/**
 * Clear any existing implementation of the selector
 */
let language_selector_reset = (): void => {
	selector_options.reset();

	selector_collection.forEach((button: LanguageSelectorButton) => {
		button.dispose();
	});
	selector_collection = [];
};

/**
 * Loads the language selector event handlers
 *  - Supports HMR by clearing any existing first
 */
let language_selector_loader = (): void => {
	Array.from(selector_elements).forEach((element: Element) => {
		let button: LanguageSelectorButton = new LanguageSelectorButton(
			new LanguageButtonOptions(
				element,
				selector_options,
				language_selector
			)
		);
		selector_collection.push(button);
	});

	language_selector.initialize();
};

language_selector_loader();

//@ts-ignore
if (module.hot) {
	//@ts-ignore
	module.hot.accept(
		[
			"./main.ts",
			"./modules/language/cookielanguageselector.ts",
      "./modules/language/interface/ilanguageselector.ts",
      "./modules/language/languagebuttonoptions.ts",
			"./modules/language/languageselectorbutton.ts",
      "./modules/language/selectoroptions.ts"
		],
		() => {
			language_selector_reset();
			language_selector_loader();
		}
	);
}
