import LanguageButtonOptions from "@/ts/modules/language/languagebuttonoptions";
import ILanguage from "@/ts/types/language/interfaces/ilanguage";
import { fromEvent, interval, Subscription } from "rxjs";
import { map, throttleTime } from "rxjs/operators";
import LanguageKey from "@/ts/types/language/languagekey";

export default class LanguageSelectorButton {
	button_subscriptions: Array<Subscription> = [];

	link_subscriptions: Array<Subscription> = [];

	selector_options: LanguageButtonOptions;

	constructor(options: LanguageButtonOptions) {
		this.selector_options = options;

		this.initialize_links(options.selector_links);
		this.initialize_label(options.selector_labels);
		this.initialize_buttons(options.selector_buttons);
	}

	dispose(): void {
		this.button_subscriptions.forEach(subscription =>
			subscription.unsubscribe()
		);
		this.link_subscriptions.forEach(subscription =>
			subscription.unsubscribe()
		);
	}

	element_children(selector: string) {
		return Array.from(
			this.selector_options.element.getElementsByClassName(selector)
		);
	}

	initialize_buttons(selector: string) {
		this.element_children(selector).forEach((element: Element) => {
			this.button_subscriptions.push(
				fromEvent(element, "click")
					.pipe(throttleTime(500))
					.subscribe(() => {
						let closing: Subscription;

						if (
							this.selector_options.element.classList.contains(
								"open"
							)
						) {
							this.selector_options.element.classList.add(
								"closing"
							);
							closing = interval(150).subscribe(() => {
								this.selector_options.element.classList.remove(
									"closing",
									"open"
								);
								closing.unsubscribe();
							});
						} else {
							this.selector_options.element.classList.add("open");
						}
					})
			);
		});
	}

	initialize_label(selector: string) {
		this.element_children(selector).forEach((element: Element) => {
			this.selector_options.options.current_language.subscribe(
				(key: LanguageKey) => {
					let language: ILanguage =
						this.selector_options.options.read_language_by_key(
							key
						) ??
						this.selector_options.options.supported_languages
							.default_language;
					element.innerHTML = "";
					element.appendChild(
						document.createTextNode(language.display_name)
					);
				}
			);
		});
	}

	initialize_links(selector: string) {
		this.element_children(selector).forEach((element: Element) => {
			this.link_subscriptions.push(
				fromEvent(element, "click")
					.pipe(
						map((event: Event): string => {
							event.preventDefault();
							return (
								(<HTMLElement>element).getAttribute(
									"data-key"
								) ?? ""
							);
						}),
						throttleTime(500)
					)
					.subscribe((language: string) => {
						this.selector_options.selector.select_language(
							new LanguageKey(language)
						);
					})
			);
		});
	}
}
