import { Subject } from "rxjs";
import ILanguages from "@/ts/types/language/interfaces/ilanguages";
import LanguageKey from "@/ts/types/language/languagekey";
import ILanguage from "@/ts/types/language/interfaces/ilanguage";

export default class SelectorOptions {
	/**
	 * Language observable subject for the current language
	 */
	current_language: Subject<LanguageKey> = new Subject<LanguageKey>();

  /**
   * Current URL/location
   */
	current_location: Location = window.location;

  /**
   * Persistent storage name to store language selector options
   */
  persistent_name: string = "trl_language_selector";

  /**
   * Toggle whether the redirect is enabled globally
   * Overrides redirect_on_load and redirect_on_select
   */
  redirect_enabled: boolean = true;

  /**
   * Toggle whether to redirect on component load
   */
  redirect_on_load: boolean = true;

  /**
   * Toggle whether to redirect on button selection
   */
  redirect_on_select: boolean = true;

  /**
	 * Set of browser language strings mapped to display names
	 */
	supported_languages: ILanguages;

	constructor(supported_languages: ILanguages) {
		this.supported_languages = supported_languages;
	}

  /**
   * Clears the current language subscription and reinitialize
   */
	reset(): void {
	  this.current_language.unsubscribe();
	  this.current_language = new Subject<LanguageKey>();
  }

	/**
	 * Read a supported language, if available, by a language key0
   * @param key
	 */
	read_language_by_key(key: LanguageKey): ILanguage | null {
		let language_segment: ILanguage | ILanguages;
		let language: ILanguage | null = null;

		if (key.language && this.supported_languages[key.language]) {
			language_segment = this.supported_languages[key.language];

			// Check if there is a single tier of the language
			if (
				"undefined" !==
				typeof (<ILanguage>language_segment).display_name
			) {
				language = <ILanguage>language_segment;
			}
			// Otherwise, check if the there is a locale specific language
			// Falling back on a local default
			else {
				if ((<ILanguages>language_segment)[key.locale]) {
					language = <ILanguage>(
						(<ILanguages>language_segment)[key.locale]
					);
				} else if (
					"undefined" !==
					typeof (<ILanguages>language_segment).default_language
				) {
					language = (<ILanguages>language_segment).default_language;
				}
			}
		}
		return language;
	}

	update_language(key: LanguageKey): void {
	  this.current_language.next(key);
  }

  use_redirect_on_load(): boolean {
	  return this.redirect_enabled && this.redirect_on_load;
  }

  use_redirect_on_select(): boolean {
    return this.redirect_enabled && this.redirect_on_select;
  }
}
