import ILanguageSelector from "@/ts/modules/language/interface/ilanguageselector";
import SelectorOptions from "@/ts/modules/language/selectoroptions";

export default class LanguageButtonOptions {
  element: Element;
  options: SelectorOptions;
  selector: ILanguageSelector;
  selector_buttons: string = "selector";
  selector_labels: string = "name";
  selector_links: string = "language";

  constructor(element: Element, options: SelectorOptions, selector: ILanguageSelector) {
    this.element = element;
    this.options = options;
    this.selector = selector;
  }
}
